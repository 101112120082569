import React, { useEffect, useState } from 'react';
import axios from 'axios';
import ReCAPTCHA from 'react-google-recaptcha';
import { Modal } from 'react-bootstrap';

const UserForm = () => {
	const [userData, setUserData] = useState(null);
	const [formData, setFormData] = useState({ username: '', senha: '', email: '' , id: '' , captcha: ''});
	const [nVar, setNvar] = useState('');
	const [tVar, setTvar] = useState(null);
	const [idVar, setIdVar] = useState(null);

	const [modal, setModal] = useState(false);
	const [recaptchaToken, setRecaptchaToken] = useState(null)

	const onRecaptchaChange = (token) => {
		setRecaptchaToken(token);
	};

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await axios.get('/api/user-data');
				setUserData(response.data);
			} catch (error) {
				console.error('Erro ao obter dados:', error);
			}
		};
		fetchData();
	}, []);

	
    useEffect(() => {
        if(tVar === null) {
            const checkStore = async () => {
                try {
                    const params = new URLSearchParams(window.location.search);
                    const pValue = params.get('n');
					const tValue = params.get('t');
					const idValue = params.get('id');
					const userDataDumb = {}
					const dumbFormData = {...formData}
					dumbFormData.id = idValue
					
                    if (pValue) {
                        setNvar(pValue)
                        setTvar(tValue)
						setIdVar(idValue)
						setUserData(userDataDumb)
						setFormData(dumbFormData)
                    } else {
                       
                    }
                } catch (error) {

                }
            };
            checkStore();
        }
    }, [tVar]);

	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData({ ...formData, [name]: value });
	};

	const handleSubmit = async (e) => {
		let dumbFormData = formData
		formData.captcha = recaptchaToken
		try {
			await axios.post('https://core.pskeromais.com.br/api/update-password', dumbFormData);
			alert('Dados enviados com sucesso!');
		} catch (error) {
			console.error('Erro ao enviar dados:', error);
		}
	};

	return (
		<div id="register-user-ctn" className="authincation">
			<div id="background-client-register" >
				<div className="row">
					<div className="col-xl-12 pt-3" style={{ height: '100vh', display: 'flex' , alignItems: 'center'}}>
						<div className="auth-form" style={{ borderRadius: 10 , width: 560 , margin: '0 auto' , backgroundColor: "rgba(241 , 241 , 241 , 0.5)"}}>
							<div
								style={{
									width: "100%",
									padding: 0,
									justifyContent: 'center'
								}}
							>
								<div className="p-3">
									{nVar && (
										<div>
											<h3><strong>Nome:</strong> {nVar}</h3>
											<h5><strong>ID:</strong> {tVar}</h5>
										</div>
									)}
									<br />
									<div  >
										<div className='"form-group mb-1">'>
											<label htmlFor="username">Username:</label>
											<input
												className="form-control"
												type="text"
												id="username"
												name="username"
												onChange={handleChange}
											/>
										</div>

										<div className='"form-group mb-1">'>
											<label htmlFor="senha">Senha:</label>
											<input
												className="form-control"
												type="password"
												id="senha"
												name="senha"
												onChange={handleChange}
											/>
										</div>

										<div className='"form-group mb-1">'>
											<label htmlFor="email">Email:</label>
											<input
												className="form-control"
												type="email"
												id="email"
												name="email"
												onChange={handleChange}
											/>
										</div>
										<br />
										<div style={{display: 'flex' , justifyContent: 'center'}}>
											<ReCAPTCHA
												sitekey={"6LdLwaQqAAAAAL4Ktttukdp8qXT5pcHHd4PFbtOS"}
												onChange={onRecaptchaChange}
											/> 
										</div>
										<br />
										<button className="btn btn-primary" type="submit" onClick={() => setModal(true)}>Enviar</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<Modal className="modal fade" show={modal}>
				<button onClick={() => setModal(false)} className='btn btn-light p-1' style={{ width: 30, height: 30, position: 'absolute', right: 6, top: 6, borderRadius: '100%' }}>
                    <i className="bi bi-x-circle" style={{ fontSize: 14 }}></i>
                </button>
				<div className='d-flex align-items-center justify-content-center flex-column p-4' style={{ minHeight: 300 }}>
					<h2 style={{ paddingBottom: 3 }}><i class="bi bi-exclamation-triangle"></i></h2>
					<p>seus dados:</p>
					<label>login:</label>
					<h4 style={{ paddingBottom: 7 }}>{formData.username}</h4>
					<label>email:</label>
					<h4 style={{ paddingBottom: 7 }}>{formData.email}</h4>
					<label>senha:</label>
					<h4 style={{ paddingBottom: 7 }}>{formData.senha}</h4>
					<button className="btn btn-primary" type="submit" onClick={() => handleSubmit( )}>Enviar</button>
				</div>
			</Modal>
		</div>
	);
};

export default UserForm;