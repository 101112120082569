import React, {useState , useEffect } from 'react';
import {Link} from 'react-router-dom';
import { Nav, Tab } from 'react-bootstrap';
import { productsFactory } from '../../services/PostsService';
import tabDataProducts from './subtabs/tabDataProducts.json'

const tabData = tabDataProducts

function Products(){
    const [products, setProducts] = useState([]);
    const [trigger, setTrigger] = useState(false);

   useEffect(() => {
      if (products.length === 0 && !trigger) {
          const fetchProducts = async () => {
              try {
                  const response = await productsFactory( 1 , 100);
                  if (response.status === 200) {
                      setProducts(response.data.result);
                  } else {
                      
                  }
              } catch (error) {
                  
              }
          };
          setTrigger(true)
          fetchProducts();
      }
  }, [products]);

    return(
        <>
            <div className="custom-tab-1 pb-3">
                <Tab.Container defaultActiveKey={tabData[0].name.toLowerCase()}>
                <Nav as="ul" className="navigatore justify-content-center">
                    {tabData.map((data, i) => (
                        <Nav.Item as="li" key={i}>
                            <Nav.Link eventKey={data.name.toLowerCase()} href={data.link}>
                            <i className={`la la-${data.icon} me-2`} />
                            {data.name}
                            </Nav.Link>
                        </Nav.Item>
                    ))}
                </Nav>
                </Tab.Container>
            </div>
            <div className="row">
                <div className="col-xl-12 pt-3">
                    <div>
                     <table className="table mb-0">
                        <thead>

                        </thead>
                        <tbody id="customers"
                           className="row"
                           style={{
                              width: "100%",
                              padding: 0,
                              justifyContent: 'center'
                           }}
                        >
                           <ul className="btn-reveal-trigger row"  style={{
                              width: "100%",
                              padding: 0
                           }}>
                              {products.map((Data, index) => (
                                 <li className="col-md-4"
                                    style={{
                                       marginBottom: 6,
                                       borderRadius: 6,
                                    }} key={index}>
                                    <div
                                       className="p-3"
                                       style={{
                                          boxShadow: '2px 2px 2px #f1f1f1',
                                       }}>
                                        <span style={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
                                            { Data.images.length > 0?
                                                  <img className="" style={{width: '100%'}} src={'https://core.pskeromais.com.br/' + Data.images[0].url}/>
                                            : <span style={{
                                                width: '100%',
                                                height: 120,
                                                backgroundImage: "gray",
                                                margin: '0 auto', 
                                                display: 'flex', 
                                                alignItems: 'center' , 
                                                justifyContent: 'center', gap: 2 , backgroundColor: 'rgb(241 241 241)' }}>
                                                <i className='bi bi-gift' style={{fontSize: 45 , color: 'white'}} />
                                              </span>  
                                            }
                                            <div className="media d-flex align-items-center">
                                                <div>
                                                    <br />
                                                    <div className="media d-flex justify-content-between">
                                                      <h3 className="mb-0 fs--1">
                                                        {Data.name}
                                                      </h3>
                                                    </div>
                                                </div>
                                            </div>
                                            <span style={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
                                              {/* <span>
                                                <span style={{color: 'rgb(245, 71, 73)'}}>
                                                  <i className="bi bi-arrows-fullscreen"></i> tamanho
                                                </span>
                                                <br />
                                                <span style={{fontSize: '15px' , fontWeight: 'bold'}}>
                                                  { Data.height + ' x ' +  Data.width + ' x ' + Data.length}
                                                </span>
                                              </span> */}
                                              <span>
                                                <span style={{color: 'rgb(245, 71, 73)'}}>
                                                  <i className="bi bi-stack" ></i> peso
                                                </span>
                                                <br />
                                                <span style={{fontSize: '15px' , fontWeight: 'bold'}}>
                                                  { Data.weight + ' g' }
                                                </span>  
                                              </span>
                                              <span>
                                                <span style={{color: 'rgb(245, 71, 73)'}}>
                                                  <i className="bi bi-clipboard" ></i> tipo
                                                </span>
                                                <br /> 
                                                <span style={{fontSize: '15px' , fontWeight: 'bold'}}>
                                                  { 
                                                    Data.categoryId === "54bd475f-7438-49fb-9967-ef0d2ba32c46"?
                                                      'Salgadinho de Milho'
                                                    :Data.categoryId === "219cbfd3-dc40-4547-ba16-b68ee3cbb758"?
                                                      'Salgadinho de Trigo'
                                                    :Data.categoryId === "d34e4917-4cdb-442e-90e8-e68adc781807"?
                                                      'Pipoca'
                                                    :null
                                                  }
                                                </span>  
                                              </span>
                                              {/* <span>
                                                <span style={{color: 'rgb(245, 71, 73)'}}>
                                                  <i className="bi bi-wallet2"></i> preço
                                                </span>
                                                  <span style={{ display: 'flex', flexDirection: 'row', gap: 8 }}>
                                                    <span style={{ width: '50%'}}>
                                                      <label style={{margin: '0'}}>custo</label> 
                                                      <hr style={{margin: '2px 0'}}/>
                                                      <span style={{fontSize: '15px' , fontWeight: 'bold'}}>{ Data.costPrice + ' R$' }</span>
                                                    </span>
                                                    <span style={{ width: '50%'}}>
                                                      <label style={{margin: '0'}}>venda</label> 
                                                      <hr style={{margin: '2px 0'}}/>
                                                      <span style={{fontSize: '15px' , fontWeight: 'bold'}}>{ Data.salePrice + ' R$' }</span>
                                                    </span>
                                                  </span>
                                              </span> */}
                                              {/* <span style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
                                                <span style={{color: 'rgb(245, 71, 73)'}}>
                                                  <i className="bi bi-archive"></i> estoque
                                                </span>
                                                { Data.store? Data.store : <span style={{ color: 'red' }}>- sem estoque -</span> }
                                              </span> */}
                                            </span>
                                        </span>
                                        <hr style={{margin: '8px 0'}}/>
                                        <span style={{ display: 'flex', flexDirection: 'row', gap: 1 }}>
                                          <a href={`/produto?p=${Data.id}`} className="btn btn-primary py-1 px-3 mt-2" style={{ maxWidth: 100 }}>
                                            ver
                                          </a>
                                          <a href={`/editar-produto?p=${Data.id}`} className="btn btn-secondary py-1 px-3 mt-2" style={{ maxWidth: 100 }}>
                                            editar
                                          </a>
                                        </span>
                                    </div>
                                 </li>
                              ))}
                           </ul>
                        </tbody>
                     </table>
                  </div>
                </div>
            </div>
        </>
    )
}
export default Products;