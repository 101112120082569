import React, { useState, useEffect } from "react";
import axios from "axios";
import { connect } from 'react-redux';
import milho from "../../images/super-kero.png";
import keromais from "../../images/keromais.png";
import ballon from "../../images/baloon.svg"
import { Stepper, Step } from 'react-form-stepper';
import { AiFillCaretRight } from "react-icons/ai";
import ReCAPTCHA from 'react-google-recaptcha';
import { createCustomer , createStore } from "../../store/actions/AuthActions";


function Register(props) {
	const [email, setEmail] = useState('');
	let errorsObj = { email: '', password: '' };
	const [errors, setErrors] = useState({});
	const [touched, setTouched] = useState({});
	const [password, setPassword] = useState('');
	const [goSteps, setGoSteps] = useState(0);
	const [dataForm, setDataForm] = useState({
		name: "",
		phone: "",
		taxId: "",
		birthDate: "",
		username: "",
		password: "",
		email: "",
		images: [],
		address: {
			number: "",
			street: "",
			city: "",
			state: "",
			zipcode: ""
		},
		captcha: ""
	});
	const [senhaConfirmar, setSenhaConfirmar] = useState('')
	const [step, setStep] = useState(0);
	const [isCNPJ, setIsCNPJ] = useState(false);
	const [recaptchaToken, setRecaptchaToken] = useState(null)

	const [MSGResponse, setMSGResponse] = useState('')
	const onRecaptchaChange = (token) => {
		setRecaptchaToken(token);
		setDataForm({ ...dataForm, ['captcha']: token });
	};

	function isCNPJsetting() {
		setIsCNPJ(true)
		setStep(2)
	}

	function Steppter() {
		return (
			<Stepper className="nav-wizard" activeStep={goSteps} label={false}>
				<Step className="nav-link" onClick={() => setGoSteps(0)} />
				<Step className="nav-link" onClick={() => setGoSteps(1)} />
				<Step className="nav-link" onClick={() => setGoSteps(2)} />
				<Step className="nav-link" onClick={() => setGoSteps(3)} />
			</Stepper>
		)
	}

	function SteppterConsumer() {
		return (
			<Stepper className="nav-wizard" activeStep={goSteps} label={false}>
				<Step className="nav-link" onClick={() => setGoSteps(0)} />
				<Step className="nav-link" onClick={() => setGoSteps(1)} />
				<Step className="nav-link" onClick={() => setGoSteps(2)} />
				<Step className="nav-link" onClick={() => setGoSteps(3)} />
			</Stepper>
		)
	}

	const formatData = (formData) => {
		const cleanNumber = (str) => str.replace(/\D/g, ""); // Remove tudo que não for número.
	
		const formattedData = {
		  ...formData,
		  phone: cleanNumber(formData.phone),
		  taxId: cleanNumber(formData.taxId),
		  address: {
			...formData.address,
			zipcode: cleanNumber(formData.address.zipcode)
		  }
		};
	
		// Remove `birthDate` se isCNPJ for true.
		if (isCNPJ) {
		  delete formattedData.birthDate;
		}
	
		return formattedData;
	};

	const validation = () => {
		const nameRegex = /^[a-zA-ZÀ-ÿ\s]{3,}$/;
		const cpfRegex = /^\d{3}\.\d{3}\.\d{3}-\d{2}$/;
		const cnpjRegex = /^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$/;
		const usernameRegex = /^[a-zA-Z0-9_]{4,}$/;
		const phoneRegex = /^\(\d{2}\)\s\d{4,5}-\d{4}$/;
		const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g
		const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
		const zipCodeRegex = /^\d{5}-\d{3}$/;

		if (step === 2) {
			const { name, taxId, username, password, birthDate } = dataForm;
			const isCnpj = taxId.length > 14;

			if (!nameRegex.test(name)) return alert("Nome inválido. Use pelo menos 3 caracteres.");
			if (isCnpj && !cnpjRegex.test(taxId)) return alert("CNPJ inválido.");
			if (!isCnpj && !cpfRegex.test(taxId)) return alert("CPF inválido.");
			if (!usernameRegex.test(username)) return alert("Username inválido. Use ao menos 4 caracteres.");
			if (password !== senhaConfirmar) return alert("As senhas não coincidem.");
			if (!isCnpj && !dateRegex.test(birthDate)) return alert("Data de nascimento inválida.");

			onSteping();
		}

		if (step === 3) {
			const { phone, email } = dataForm;

			//if (!phoneRegex.test(phone)) return alert("Telefone inválido. Use o formato (XX) XXXXX-XXXX.");
			//if (!emailRegex.test(email)) return alert("Email inválido.");

			onSteping();
		}

		if (step === 4) {
			const { address } = dataForm;
			const { number, street, city, state, zipcode } = address;

			if (!number || !street || !city || !state || !zipcode) {
				return alert("Preencha todos os campos do endereço.");
			}
			// if (!zipCodeRegex.test(zipcode)) return alert("CEP inválido. Use o formato XXXXX-XXX.");

			onSteping();
		}

		if (step === 5) {
			alert("Todas as etapas foram concluídas!");
		}
	};

	const handleAddressChange = (e) => {
		const { name, value } = e.target;
		setDataForm({
			...dataForm,
			address: {
				...dataForm.address,
				[name]: value,
			},
		});
	};

	const handleChange = (e) => {
		const { name, value } = e.target;
		setDataForm({ ...dataForm, [name]: value });
	};

	function onSteping() {
		setStep(step + 1);
		setGoSteps(goSteps + 1);
	}

	const sendCreateCustomer = async () => {
		try {
		  const formattedData = formatData(dataForm);
		  const response = await createCustomer(formattedData);
	
		  if (response.status === 200) {
			onSteping()
			setMSGResponse("seu cadastro foi enviado com sucesso");
		  } else {
			onSteping()
			setMSGResponse("Algo deu errado, tente novamente");
		  }
		} catch (error) {
		  console.error("Error creating customer:", error);
		}
	};

	const sendCreateStore = async () => {
		try {
		  const formattedData = formatData(dataForm);
		  const response = await createStore(formattedData);
	
		  if (response.status === 200) {
			onSteping()
			console.log("seu cadastro foi enviado com sucesso, aguarde a aprovação para poder continuar");
		  } else {
			onSteping()
			setMSGResponse("Failed to create store.");
		  }
		} catch (error) {
		  console.error("Error creating store:", error);
		}
	};

	useEffect(() => {
		let dumbstepper = goSteps + 2
		if (step >= 3) {
			setStep(dumbstepper)
		}
	}, [goSteps])

	useEffect(() => {
		console.log('dataForm')
		console.log(dataForm)
		console.log('-------------')
		console.log(MSGResponse)
	}, [dataForm , senhaConfirmar])

	return (
		<div id="register-user-ctn" className="authincation">
			<div id="background-client-register" >
			</div>
			<div className="container" >
				<div className="row">
					{step > 0 && step < 6?
						<div className="col-md-12" style={{ marginRight: 'auto', marginLeft: 'auto', marginBottom: 30 }}>
							<img src={keromais} alt="" style={{ maxWidth: 120, marginRight: 'auto', marginLeft: 'auto', display: 'block', marginBottom: 3, position: "relative" }} />
						</div>
						: null}
					<div style={{ display: "flex", width: '100%', justifyContent: 'center' }}>
						{step > 0 && step < 6 ?
							<span className='ctn-principal ctn-um' style={{ justifyContent: "center", zIndex: 10 }}>
								<span>
									<div id="caixa-do-kero">
										{(() => {
											switch (step) {
												case 1:
													return 'escolha como você vai se cadastrar'
													break;
												case 2:
													return 'aqui você precisa dizer quem é'
													break;
												case 3:
													return 'suas informações de contato'
													break;
												case 4:
													if (isCNPJ) {
														return 'endereço de sua empresa'
													} else {
														return 'diga onde você mora'
													}
													break;
												case 5:
													if (isCNPJ) {
														return 'certifique-se está tudo correto!'
													} else {
														return 'veja agora se preencheu tudo corretamente'
													}
													break;
												default:
													return 'defaulte'
											}
										})()}
									</div>
									<img id="super-kero-ballon" src={ballon} alt="" />
									<img id="super-kero-cadastro" src={milho} alt="" style={{ maxWidth: 140 }} />
								</span>
							</span>
							: null}
						<div className='ctn-principal ctn-dois' style={{ position: "relative" }}>
							<div style={{ zIndex: 10, width: '100%' }}>

								{
									step === 0 ?
										<div id="welcome" className="flex justify-center">
											<img src={milho} alt="" />
											<span id="delivery">
												<span><div className='arabesco' /></span>
												<span>DELIVERY</span>
												<span><div className='arabesco' /></span>
											</span>
											<img src={keromais} alt="" />
											<button id="btn-welcome" className="btn btn-primary  mt-4" onClick={() => setStep(1)}>CADASTRAR-SE</button>
										</div>
										: step === 1 ?
											<div className="authincation-content">
												<span className="content-span">
													<div className="row no-gutters">
														<div className="col-xl-12">
															<div className="auth-form" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', paddingBottom: 30, minHeight: 350 }}>
																<button className="btn btn-primary  mt-4" onClick={() => isCNPJsetting()} style={{ maxWidth: 400 }}>LOJISTA</button>
																<button className="btn btn-primary  mt-4" onClick={() => setStep(2)} style={{ maxWidth: 400 }}>CONSUMIDOR</button>
															</div>
														</div>
													</div>
												</span>
											</div>

											: step === 2 ?
												isCNPJ ?
													<div className="authincation-content">
														<span className="content-span">
															<div className="row no-gutters">
																<div className="col-xl-12">
																	<div className="auth-form" style={{ borderRadius: 10 }}>
																		<h1 className="text-center mb-0 cor-azul">Dados Pessoais</h1>
																		{
																			SteppterConsumer()
																		}
																		<div className="form-group mb-1">
																			<label className="mb-1" style={{ display: 'inline' }}>
																				<strong>Nome Completo</strong>
																			</label>
																			<input
																				type="text"
																				className="form-control"
																				placeholder="..."
																				name="name"
																				onChange={handleChange}
																				value={dataForm.name}
																			/>
																		</div>
																		<div className="form-group mb-1">
																			<label className="mb-1" style={{ display: 'inline' }}>
																				<strong>CNPJ</strong>
																			</label>
																			<input
																				type="text"
																				className="form-control"
																				placeholder="..."
																				name="taxId"
																				onChange={handleChange}
																				value={dataForm.taxId}
																			/>
																		</div>
																		<div className="form-group mb-1">
																			<label className="mb-1" style={{ display: 'inline' }}>
																				<strong>username</strong>
																			</label>
																			<input
																				type="text"
																				className="form-control"
																				placeholder="..."
																				name="username"
																				onChange={handleChange}
																				value={dataForm.username}
																			/>
																		</div>
																		<div className="form-group mb-1">
																			<label className="mb-1" style={{ display: 'inline' }}>
																				<strong>Senha</strong>
																			</label>
																			<input
																				type="password"
																				className="form-control"
																				placeholder="..."
																				name="password"
																				onChange={handleChange}
																				value={dataForm.password}
																			/>
																		</div>
																		<div className="form-group mb-1">
																			<label className="mb-1" style={{ display: 'inline' }}>
																				<strong>Confirmar Senha</strong>
																			</label>
																			<input
																				type="password"
																				className="form-control"
																				placeholder="..."
																				name="confirmar"
																				onChange={(e) => setSenhaConfirmar(e.target.value)}
																				value={senhaConfirmar}
																			/>
																		</div>
																		<div className="text-center mt-4">
																			<button
																				onClick={() => validation()}
																				className="btn btn-primary ">
																				Próxima Etapa <AiFillCaretRight />
																			</button>
																		</div>
																	</div>
																</div>
															</div>
														</span>
													</div>
													:
													<div className="authincation-content">
														<span className="content-span">
															<div className="row no-gutters">
																<div className="col-xl-12">
																	<div className="auth-form">
																		<h1 className="text-center mb-0 cor-azul">Dados Pessoais</h1>
																		{Steppter()}
																		<div className="form-group mb-1">
																			<label className="mb-1" style={{ display: 'inline' }}>
																				<strong>Nome Completo</strong>
																			</label>
																			<input
																				type="text"
																				className="form-control"
																				placeholder="..."
																				name="name"
																				onChange={handleChange}
																				value={dataForm.name}
																			/>
																		</div>
																		<div className="form-group mb-1">
																			<label className="mb-1" style={{ display: 'inline' }}>
																				<strong>CPF</strong>
																			</label>
																			<input
																				type="text"
																				className="form-control"
																				placeholder="..."
																				name="taxId"
																				onChange={handleChange}
																				value={dataForm.taxId}
																			/>
																		</div>
																		<div className="form-group mb-1">
																			<label className="mb-1" style={{ display: 'inline' }}>
																				<strong>Data de Nascimento</strong>
																			</label>
																			<input
																				type="date"
																				className="form-control"
																				placeholder="Selecione a data"
																				name="birthDate"
																				value={dataForm.birthDate}
																				onChange={handleChange}
																			/>
																		</div>
																		<div className="form-group mb-1">
																			<label className="mb-1" style={{ display: 'inline' }}>
																				<strong>username</strong>
																			</label>
																			<input
																				type="text"
																				className="form-control"
																				placeholder="..."
																				name="username"
																				onChange={handleChange}
																				value={dataForm.username}
																			/>
																		</div>
																		<div className="form-group mb-1">
																			<label className="mb-1" style={{ display: 'inline' }}>
																				<strong>Senha</strong>
																			</label>
																			<input
																				type="password"
																				className="form-control"
																				placeholder="..."
																				name="password"
																				onChange={handleChange}
																				value={dataForm.password}
																			/>
																		</div>
																		<div className="form-group mb-1">
																			<label className="mb-1" style={{ display: 'inline' }}>
																				<strong>Confirmar Senha</strong>
																			</label>
																			<input
																				type="password"
																				className="form-control"
																				placeholder="..."
																				name="confirmar"
																				onChange={(e) => setSenhaConfirmar(e.target.value)}
																				value={senhaConfirmar}
																			/>
																		</div>
																		<div className="text-center mt-4">
																			<button
																				onClick={() => validation()}
																				className="btn btn-primary ">
																				Próxima Etapa <AiFillCaretRight />
																			</button>
																		</div>
																	</div>
																</div>
															</div>
														</span>
													</div>
												: step === 3 ?
													<div className="authincation-content">
														<span className="content-span">
															<div className="row no-gutters">
																<div className="col-xl-12">
																	<div className="auth-form">
																		<h1 className="text-center mb-0 cor-azul">Contato</h1>

																		{
																			isCNPJ ?
																				Steppter()
																				:
																				SteppterConsumer()
																		}
																		<div className="form-group mb-1">
																			<label className="mb-1" style={{ display: 'inline' }}>
																				<strong>Email</strong>
																			</label>
																			<input
																				type="text"
																				className="form-control"
																				placeholder="..."
																				name="email"
																				onChange={handleChange}
																				value={dataForm.email}
																			/>
																		</div>
																		<div className="form-group mb-1">
																			<label className="mb-1" style={{ display: 'inline' }}>
																				<strong>Telefone</strong>
																			</label>
																			<input
																				type="text"
																				className="form-control"
																				placeholder="..."
																				name="phone"
																				onChange={handleChange}
																				value={dataForm.phone}
																			/>
																		</div>
																		<div className="text-center mt-4">
																			<button
																				onClick={() => validation()}
																				className="btn btn-primary ">
																				Próxima Etapa <AiFillCaretRight />
																			</button>
																		</div>
																	</div>
																</div>
															</div>
														</span>
													</div>
													: step === 4 ?
														<div className="authincation-content">
															<span className="content-span">
																<div className="row no-gutters">
																	<div className="col-xl-12">
																		<div className="auth-form">
																			<h1 className="text-center mb-0 cor-azul">Endereço</h1>
																			{
																				!isCNPJ ?
																					SteppterConsumer()
																					:
																					Steppter()
																			}
																			<div className="form-group mb-1">
																				<label className="mb-1" style={{ display: 'inline' }}>
																					<strong>Número</strong>
																				</label>
																				<input
																					type="text"
																					className="form-control"
																					placeholder="..."
																					name="number"
																					onChange={handleAddressChange}
																					value={dataForm.address.number}
																				/>
																			</div>
																			<div className="form-group mb-1">
																				<label className="mb-1" style={{ display: 'inline' }}>
																					<strong>Rua</strong>
																				</label>
																				<input
																					type="text"
																					className="form-control"
																					placeholder="..."
																					name="street"
																					onChange={handleAddressChange}
																					value={dataForm.address.street}
																				/>
																			</div>
																			<div className="form-group mb-1">
																				<label className="mb-1" style={{ display: 'inline' }}>
																					<strong>Cidade</strong>
																				</label>
																				<input
																					type="text"
																					className="form-control"
																					placeholder="..."
																					name="city"
																					onChange={handleAddressChange}
																					value={dataForm.address.city}
																				/>
																			</div>
																			<div className="form-group mb-1">
																				<label className="mb-1" style={{ display: 'inline' }}>
																					<strong>Estado</strong>
																				</label>
																				<input
																					type="text"
																					className="form-control"
																					placeholder="..."
																					name="state"
																					onChange={handleAddressChange}
																					value={dataForm.address.state}
																				/>
																			</div>
																			<div className="form-group mb-1">
																				<label className="mb-1" style={{ display: 'inline' }}>
																					<strong>CEP</strong>
																				</label>
																				<input
																					type="text"
																					className="form-control"
																					placeholder="..."
																					name="zipcode"
																					onChange={handleAddressChange}
																					value={dataForm.address.zipcode}
																				/>
																			</div>
																			<div className="text-center mt-4">
																				<button
																					onClick={() => validation()}
																					className="btn btn-primary ">
																					Próxima Etapa <AiFillCaretRight />
																				</button>
																			</div>
																		</div>
																	</div>
																</div>
															</span>
														</div>
														: step === 5 ?
															isCNPJ ?
																<div className="authincation-content">
																	<span className="content-span">
																		<div className="row no-gutters">
																			<div className="col-xl-12">
																				<div className="auth-form">
																					<h1 className="text-center mb-0 cor-azul">rever dados</h1>
																					{
																						!isCNPJ ?
																							SteppterConsumer()
																							:
																							Steppter()
																					}
																					<div className="d-flex flex-row mt-3 mb-2" style={{ gap: '1rem', flexWrap: "wrap" }}>
																						<div className="mb-1 text-left">
																							<label className="mb-1" style={{ display: 'flex', justifyContent: 'flex-start' }}>
																								<strong style={{ fontSize: 8 }}>Nome Completo</strong>
																							</label>
																							<span style={{padding: 4, borderRadius: 6, backgroundColor: '#dedede'}}>{ dataForm.name }</span>
																						</div>
																						<div className="mb-1 text-left">
																							<label className="mb-1" style={{ display: 'flex', justifyContent: 'flex-start' }}>
																								<strong style={{ fontSize: 8 }}>CPF</strong>
																							</label>
																							<span style={{padding: 4, borderRadius: 6, backgroundColor: '#dedede'}}>{ dataForm.taxId }</span>
																						</div>
																						<div className="mb-1 text-left">
																							<label className="mb-1" style={{ display: 'flex', justifyContent: 'flex-start' }}>
																								<strong style={{ fontSize: 8 }}>Senha</strong>
																							</label>
																							<span style={{padding: 4, borderRadius: 6, backgroundColor: '#dedede'}}>{ dataForm.password }</span>
																						</div>
																					</div>
																					<div style={{ display: "flex", justifyContent: "space-between" }}>
																						<h5 className="mb-0"><i className="bi bi-person-lines-fill" style={{ marginRight: '7px' }}></i> Contato</h5>
																						<div style={{ width: "calc(120 - 100%)", height: 0.6, backgroundColor: "#d1d1d1", marginTop: '0.6rem' }} />
																					</div>
																					<div className="d-flex flex-row mt-3 mb-3" style={{ gap: '1rem', flexWrap: "wrap" }}>
																						<div className="mb-1 text-left">
																							<label className="mb-1" style={{ display: 'flex', justifyContent: 'flex-start' }}>
																								<strong style={{ fontSize: 8 }}>Telefone</strong>
																							</label>
																							<span style={{padding: 4, borderRadius: 6, backgroundColor: '#dedede'}}>{ dataForm.phone }</span>
																						</div>
																						<div className="mb-1 text-left">
																							<label className="mb-1" style={{ display: 'flex', justifyContent: 'flex-start' }}>
																								<strong style={{ fontSize: 8 }}>Email</strong>
																							</label>
																							<span style={{padding: 4, borderRadius: 6, backgroundColor: '#dedede'}}>{ dataForm.email }</span>
																						</div>
																					</div>
																					<div style={{ display: "flex", justifyContent: "space-between" }}>
																						<h5 className="mb-0"><i className="bi bi-geo-alt-fill" style={{ marginRight: '7px' }}></i> Endereço</h5>
																						<div style={{ width: "calc(120 - 100%)", height: 0.6, backgroundColor: "#d1d1d1", marginTop: '0.6rem' }} />
																					</div>
																					<div className="d-flex flex-row mt-3 mb-3" style={{ gap: '1rem', flexWrap: "wrap" }}>
																						<div className="mb-1 text-left">
																							<label className="mb-1" style={{ display: 'flex', justifyContent: 'flex-start' }}>
																								<strong style={{ fontSize: 8 }}>Rua</strong>
																							</label>
																							<span style={{padding: 4, borderRadius: 6, backgroundColor: '#dedede'}}>{ dataForm.address.street }</span>
																						</div>
																						<div className="mb-1 text-left">
																							<label className="mb-1" style={{ display: 'flex', justifyContent: 'flex-start' }}>
																								<strong style={{ fontSize: 8 }}>Número</strong>
																							</label>
																							<span style={{padding: 4, borderRadius: 6, backgroundColor: '#dedede'}}>{ dataForm.address.number }</span>
																						</div>
																						<div className="mb-1 text-left">
																							<label className="mb-1" style={{ display: 'flex', justifyContent: 'flex-start' }}>
																								<strong style={{ fontSize: 8 }}>Cidade</strong>
																							</label>
																							<span style={{padding: 4, borderRadius: 6, backgroundColor: '#dedede'}}>{ dataForm.address.city }</span>
																						</div>
																						<div className="mb-1 text-left">
																							<label className="mb-1" style={{ display: 'flex', justifyContent: 'flex-start' }}>
																								<strong style={{ fontSize: 8 }}>Estado</strong>
																							</label>
																							<span style={{padding: 4, borderRadius: 6, backgroundColor: '#dedede'}}>{ dataForm.address.zipcode }</span>
																						</div>
																					</div>
																					<div style={{display: 'flex' , justifyContent: 'center'}}>
																						<ReCAPTCHA
																							sitekey={"6LdLwaQqAAAAAL4Ktttukdp8qXT5pcHHd4PFbtOS"}
																							onChange={onRecaptchaChange}
																						/> 
																					</div>
																					<div className="text-center mt-4">
																						<button
																							onClick={() => sendCreateCustomer()}
																							className="btn btn-primary ">
																							Enviar Cadastro
																						</button>
																					</div>
																				</div>
																			</div>
																		</div>
																	</span>
																</div>
																:
																<div className="authincation-content">
																	<span className="content-span">
																		<div className="row no-gutters">
																			<div className="col-xl-12">
																				<div className="auth-form">
																					<h1 className="text-center mb-0 cor-azul">rever dados</h1>
																					{
																						!isCNPJ ?
																							SteppterConsumer()
																							:
																							Steppter()
																					}
																					<div className="d-flex flex-row mt-3 mb-2" style={{ gap: '1rem', flexWrap: "wrap" }}>
																						<div className="mb-1 text-left">
																							<label className="mb-1" style={{ display: 'flex', justifyContent: 'flex-start' }}>
																								<strong style={{ fontSize: 8 }}>Nome Completo</strong>
																							</label>
																							<span style={{padding: 4, borderRadius: 6, backgroundColor: '#dedede'}}>{ dataForm.name }</span>
																						</div>
																						<div className="mb-1 text-left">
																							<label className="mb-1" style={{ display: 'flex', justifyContent: 'flex-start' }}>
																								<strong style={{ fontSize: 8 }}>CPF</strong>
																							</label>
																							<span style={{padding: 4, borderRadius: 6, backgroundColor: '#dedede'}}>{ dataForm.taxId }</span>
																						</div>
																						<div className="mb-1 text-left">
																							<label className="mb-1" style={{ display: 'flex', justifyContent: 'flex-start' }}>
																								<strong style={{ fontSize: 8 }}>Senha</strong>
																							</label>
																							<span style={{padding: 4, borderRadius: 6, backgroundColor: '#dedede'}}>{ dataForm.password }</span>
																						</div>
																					</div>
																					<div style={{ display: "flex", justifyContent: "space-between" }}>
																						<h5 className="mb-0"><i className="bi bi-person-lines-fill" style={{ marginRight: '7px' }}></i> Contato</h5>
																						<div style={{ width: "calc(120 - 100%)", height: 0.6, backgroundColor: "#d1d1d1", marginTop: '0.6rem' }} />
																					</div>
																					<div className="d-flex flex-row mt-3 mb-3" style={{ gap: '1rem', flexWrap: "wrap" }}>
																						<div className="mb-1 text-left">
																							<label className="mb-1" style={{ display: 'flex', justifyContent: 'flex-start' }}>
																								<strong style={{ fontSize: 8 }}>Telefone</strong>
																							</label>
																							<span style={{padding: 4, borderRadius: 6, backgroundColor: '#dedede'}}>{ dataForm.phone }</span>
																						</div>
																						<div className="mb-1 text-left">
																							<label className="mb-1" style={{ display: 'flex', justifyContent: 'flex-start' }}>
																								<strong style={{ fontSize: 8 }}>Email</strong>
																							</label>
																							<span style={{padding: 4, borderRadius: 6, backgroundColor: '#dedede'}}>{ dataForm.email }</span>
																						</div>
																					</div>
																					<div style={{ display: "flex", justifyContent: "space-between" }}>
																						<h5 className="mb-0"><i className="bi bi-geo-alt-fill" style={{ marginRight: '7px' }}></i> Endereço</h5>
																						<div style={{ width: "calc(120 - 100%)", height: 0.6, backgroundColor: "#d1d1d1", marginTop: '0.6rem' }} />
																					</div>
																					<div className="d-flex flex-row mt-3 mb-3" style={{ gap: '1rem', flexWrap: "wrap" }}>
																						<div className="mb-1 text-left">
																							<label className="mb-1" style={{ display: 'flex', justifyContent: 'flex-start' }}>
																								<strong style={{ fontSize: 8 }}>Rua</strong>
																							</label>
																							<span style={{padding: 4, borderRadius: 6, backgroundColor: '#dedede'}}>{ dataForm.address.street }</span>
																						</div>
																						<div className="mb-1 text-left">
																							<label className="mb-1" style={{ display: 'flex', justifyContent: 'flex-start' }}>
																								<strong style={{ fontSize: 8 }}>Número</strong>
																							</label>
																							<span style={{padding: 4, borderRadius: 6, backgroundColor: '#dedede'}}>{ dataForm.address.number }</span>
																						</div>
																						<div className="mb-1 text-left">
																							<label className="mb-1" style={{ display: 'flex', justifyContent: 'flex-start' }}>
																								<strong style={{ fontSize: 8 }}>Cidade</strong>
																							</label>
																							<span style={{padding: 4, borderRadius: 6, backgroundColor: '#dedede'}}>{ dataForm.address.city }</span>
																						</div>
																						<div className="mb-1 text-left">
																							<label className="mb-1" style={{ display: 'flex', justifyContent: 'flex-start' }}>
																								<strong style={{ fontSize: 8 }}>Estado</strong>
																							</label>
																							<span style={{padding: 4, borderRadius: 6, backgroundColor: '#dedede'}}>{ dataForm.address.zipcode }</span>
																						</div>
																					</div>
																					<div style={{display: 'flex' , justifyContent: 'center'}}>
																						<ReCAPTCHA
																							sitekey={"6LdLwaQqAAAAAL4Ktttukdp8qXT5pcHHd4PFbtOS"}
																							onChange={onRecaptchaChange}
																						/> 
																					</div>
																					<div className="text-center mt-4">
																						<button
																							onClick={() => sendCreateStore()}
																							className="btn btn-primary ">
																							Próxima Etapa <AiFillCaretRight />
																						</button>
																					</div>
																				</div>
																			</div>
																		</div>
																	</span>
																</div>
															

															: step === 6 ?
																<div style={{display: 'flex' , flexDirection: "column", alignItems: "center"}}>
																	<img id="super-kero-ballon" src={ballon} alt="" />
																	<p>{MSGResponse}</p>
																	<img src={milho} alt="" style={{ maxWidth: 230, marginRight: 'auto', marginLeft: 'auto', display: 'block', marginBottom: 3, position: "relative" }} />
																</div>
															: null
								}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div >
	);
};

const mapStateToProps = (state) => {
	return {
		errorMessage: state.auth.errorMessage,
		successMessage: state.auth.successMessage,
		showLoading: state.auth.showLoading,
	};
};

export default connect(mapStateToProps)(Register);