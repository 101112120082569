import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Dropdown, Modal, Row, Col, Card, Table, Nav, Tab } from 'react-bootstrap';
import { productFactory } from '../../services/PostsService';
import tabDataProducts from './subtabs/tabDataProducts.json'

const tabData = tabDataProducts

function Product() {
    const [product, setProduct] = useState('');
    const [productId, setProductId] = useState(null);
    const [toggle, setToggle] = useState(false);
    const [Data, setData] = useState({
        Name: 'Pipoca Amarela',
        Weight: 15,
        SalePrice: 1.5,
        CostPrice: 1.5,
        Height: 10,
        Width: 6,
        Length: 0.4,
        Store: null,
        Description: 'Prepare-se para uma aventura saborosa com a Pipoca Amarela Kero Mais! Este pacote de 15g é a companhia perfeita para uma tarde cheia de diversão. Com seu vibrante amarelo e um sabor que faz o paladar dançar de alegria, cada mordida é uma explosão de felicidade. Crocante, deliciosa e cheia de magia, a Pipoca Amarela é a escolha certa para crianças que adoram um lanche especial e cheio de cor.',
        Images: []
    });

    //pega o valor get de p na url

    useEffect(() => {
        if(productId === null) {
            const checkProduct = async () => {
                if (product === '') {
                    try {
                        const params = new URLSearchParams(window.location.search);
                        const pValue = params.get('p');
                        if (pValue) {
                            setProductId(pValue)
                            setToggle(true)
                        } else {
                           
                        }
                    } catch (error) {

                    }
                }
            };
            checkProduct();
        }
    }, [productId]);

    //envia o ID para a consulta na api

    useEffect(() => {
        if (toggle) {
            const fetchProducts = async () => {
                try {
                    const response = await productFactory(productId);
                    if (response.status === 200) {
                        console.log(response.data.result)
                        setProduct(response.data.result);
                        setToggle(false)
                    } else {
                        setToggle(false)
                    }
                } catch (error) {
                    setToggle(false)
                }
            };
  
            fetchProducts();
        }
    }, [productId , product]);

    return (
        <>
            <div className="custom-tab-1 pb-3">
                <Tab.Container>
                    <Nav as="ul" className="navigatore justify-content-center">
                        {tabData.map((data, i) => (
                            <Nav.Item as="li" key={i}>
                                <Nav.Link eventKey={data.name.toLowerCase()} href={data.link}>
                                    <i className={`la la-${data.icon} me-2`} />
                                    {data.name}
                                </Nav.Link>
                            </Nav.Item>
                        ))}
                    </Nav>
                </Tab.Container>
            </div>
            <div className="row">
                <div className="col-xl-12 pt-3">
                    {
                        !toggle?
                            <div id="customers"
                                className="row"
                                style={{
                                    width: "100%",
                                    padding: 0,
                                    justifyContent: 'center'
                                }}
                            >
                                <div className="col-md-6"
                                    style={{
                                        marginBottom: 6,
                                        borderRadius: 6,
                                    }}>
                                    <div className="p-3">
                                        <span style={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
                                                {product.images.length === 0 ? (
                                                <span style={{
                                                    width: '100%',
                                                    height: 180,
                                                    backgroundImage: "gray",
                                                    margin: '0 auto',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center', gap: 2, backgroundColor: 'rgb(241 241 241)'
                                                }}>
                                                    <i className='bi bi-gift' style={{ fontSize: 45, color: 'white' }} />
                                                </span>
                                            ): 
                                                <img className="" style={{ width: '100%'}} src={'https://core.pskeromais.com.br/' + product.images[0].url}/>
                                            }
                                        </span>
                                    </div>
                                </div>
                                <div className="col-md-6"
                                    style={{
                                        marginBottom: 6,
                                        borderRadius: 6,
                                    }}>
                                    <div
                                        className="p-3">
                                        <span style={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
                                            <div className="media d-flex justify-content-between">
                                                <div>
                                                    <br />
                                                    <h3 className="mb-0 fs--1">
                                                        {product.name}
                                                    </h3>
                                                </div>
                                                <div className="media d-flex mt-3" style={{gap: 4}}>
                                                    <button className='btn btn-secondary' style={{padding: 0 , border: 0, width: 33 , height: 33, borderRadius: '100%'}}>
                                                      <i className="bi bi-pencil-square"></i>
                                                    </button>
                                                    <button className='btn btn-primary' style={{padding: 0 , border: 0, width: 33 , height: 33, borderRadius: '100%'}}>
                                                      <i className="bi bi-x-circle"></i>
                                                    </button>  
                                                </div>
                                            </div>
                                            <hr />
                                            <p style={{textIndent: '24px', textAlign: 'justify'}}>
                                                {product.description}
                                            </p>
                                            <span style={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
                                                <span>
                                                    <span style={{color: 'rgb(245, 71, 73)'}}>
                                                    <i className="bi bi-clipboard" ></i> tipo
                                                    </span>
                                                    <br /> 
                                                    <span style={{fontSize: '15px' , fontWeight: 'bold'}}>
                                                    { 
                                                        product.categoryId === "54bd475f-7438-49fb-9967-ef0d2ba32c46"?
                                                        'Salgadinho de Milho'
                                                        :product.categoryId === "219cbfd3-dc40-4547-ba16-b68ee3cbb758"?
                                                        'Salgadinho de Trigo'
                                                        :product.categoryId === "d34e4917-4cdb-442e-90e8-e68adc781807"?
                                                        'Pipoca'
                                                        :null
                                                    }
                                                    </span>  
                                                </span>
                                                <span>
                                                    <span style={{ color: 'rgb(245, 71, 73)' }}>
                                                        <i className="bi bi-stack" ></i> peso
                                                    </span>
                                                    <br />
                                                    <span style={{ fontSize: '15px', fontWeight: 'bold' }}>
                                                        {product.weight + ' g'}
                                                    </span>
                                                </span>
                                                {/* <span>
                                                    <span style={{ color: 'rgb(245, 71, 73)' }}>
                                                        <i className="bi bi-wallet2"></i> preço:
                                                    </span>
                                                    <span style={{ display: 'flex', flexDirection: 'row', gap: 8 }}>
                                                        <span style={{ width: '50%' }}>
                                                            <label style={{ margin: '0' }}>custo</label>
                                                            <hr style={{ margin: '2px 0' }} />
                                                            <span style={{ fontSize: '15px', fontWeight: 'bold' }}>{product.costPrice + ' R$'}</span>
                                                        </span>
                                                        <span style={{ width: '50%' }}>
                                                            <label style={{ margin: '0' }}>venda</label>
                                                            <hr style={{ margin: '2px 0' }} />
                                                            <span style={{ fontSize: '15px', fontWeight: 'bold' }}>{product.salePrice + ' R$'}</span>
                                                        </span>
                                                    </span>
                                                </span>
                                                <span style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
                                                    <span style={{ color: 'rgb(245, 71, 73)' }}>
                                                        <i className="bi bi-archive"></i> estoque:
                                                    </span>
                                                    {Data.Store ? Data.Store : <span style={{ color: 'red' }}>- sem estoque -</span>}
                                                </span> */}
                                            </span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            :
                            ' produto não encontrado '
                    }
                </div>
            </div>
        </>
    )
}
export default Product;