import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Nav, Tab } from 'react-bootstrap';
import { shoppersFactory } from '../../services/PostsService';
import tabDataUsers from './subtabs/tabDataUsers.json'
import { Modal } from 'react-bootstrap';
import { filterShops , approveStore , rejectStore , inactivateStore } from '../../services/UserService';

const tabData = tabDataUsers

function Applys() {
  const [users, setUsers] = useState([]);
  const [modalManagerStore, setModalManagerStore] = useState(false);
  const [objManaged, setObjManaged] = useState({});

  const [products, setProducts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(null);
  const [productNameSearch, setProductNameSearch] = useState('');
  const [onSearch, setOnSearch] = useState(false);
  const [lastSearch, setLastSearch] = useState('');
  const [searchSteps, setSearchSteps] = useState('name');
  const [modalSearch, setModalSearch] = useState(false);
  const [searchValueType, setValueType] = useState('name');

  const gerenciarStore = (obj) => {
    setModalManagerStore(true)
    setObjManaged(obj)
  }

  const closeGerenciarStore = () => {
    setModalManagerStore(true)
    setObjManaged({})
  }

  useEffect(() => {
    if (users.length === 0) {
      const fetchProducts = async () => {
        try {
          const response = await shoppersFactory(1, 20);
          if (response.status === 200) {
            setUsers(response.data.result);
          } else {
            console.error('Erro ao obter os produtos:', response.statusText);
          }
        } catch (error) {
          console.error('Erro ao obter os produtos:', error);
        }
      };
      fetchProducts();
    }
  }, [users]);

  const handleStoreFilter = (searchValueType, value) => {

    const storeFilter = async () => {

      try {
        const response = await filterShops(value, searchValueType, currentPage, 20);
        if (response.status === 200) {
          setUsers(response.data.result);
          setOnSearch(true);
          setLastSearch(value);
          setModalSearch(false);
        }
      } catch (error) {
        console.error("Erro ao  filtrar as lojas:", error);
      }
    };
    storeFilter();
  }

  return (
    <>
      <div className="custom-tab-1 pb-3">
        <Tab.Container defaultActiveKey={tabData[2].name.toLowerCase()}>
          <Nav as="ul" className="navigatore justify-content-center">
            {tabData.map((data, i) => (
              <Nav.Item as="li" key={i}>
                <Nav.Link eventKey={data.name.toLowerCase()} href={data.link}>
                  <i className={`la la-${data.icon} me-2`} />
                  {data.name}
                </Nav.Link>
              </Nav.Item>
            ))}
          </Nav>
        </Tab.Container>
      </div>
      <div className="row">
        <div className="col-xl-12 pt-3">
          {!onSearch ?
            <span style={{ padding: '5px 0 20px 0', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', alignContent: 'center', gap: 5, width: '100%' }}>
              <button className="btn btn-primary-outline border-primary text-primary" onClick={() => setModalSearch(true)}>
                procurar loja
              </button>
            </span>
            : onSearch ?
              <div style={{ padding: '5px 0 20px 0', display: 'flex', flexDirection: 'row', gap: 10, justifyContent: 'center' }}>
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                  <label><i className="fa-solid fa-magnifying-glass"></i> buscando por:</label>
                  <h4 style={{ backgroundColor: 'rgb(241, 241, 241)', padding: "6px 17px" }}><b className="text-primary">{lastSearch}</b></h4>
                </div>
                <span style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', alignContent: 'center', gap: 5 }}>
                  <button className="btn btn-primary-outline border-primary text-primary" onClick={() => setModalSearch(true)}>
                    procurar nova loja
                  </button>
                </span>
              </div>
              : null
          }
          <div>
            <table className="table mb-0">
              <thead>

              </thead>
              <tbody id="customers"
                className="row"
                style={{
                  width: "100%",
                  padding: 0,
                  justifyContent: 'center'
                }}
              >
                <ul className="btn-reveal-trigger row" style={{
                  width: "100%",
                  padding: 0
                }}>
                  {users.map((Data, index) => (

                    <li className="col-md-4"
                      style={{
                        marginBottom: 6,
                        borderRadius: 6,
                      }} key={index}>
                      <div
                        className="p-3"
                        style={{
                          boxShadow: '2px 2px 2px #f1f1f1',
                        }}>
                        <span style={{ display: 'flex', flexDirection: 'column', gap: 3 }}>

                          {
                            Data.status === 0 ?
                              <span className='btn btn-outline-secondary text-secondary' style={{ with: '100%', margin: '10px 0', color: 'white', textAlign: 'center' }}>
                                pendente
                              </span>
                              : Data.status === 1 ?
                                <span className='btn btn-outline-success  text-success' style={{ with: '100%', margin: '10px 0', color: 'white', textAlign: 'center' }}>
                                  aprovado
                                </span>
                                : Data.status === 2 ?
                                  <span className='btn btn-outline-danger  text-danger' style={{ with: '100%', margin: '10px 0', color: 'white', textAlign: 'center' }}>
                                    rejeitado
                                  </span>
                                  : Data.status === 3 ?
                                    <span className='btn btn-outline-dark  text-dark' style={{ with: '100%', margin: '10px 0', color: 'white', textAlign: 'center' }}>
                                      cancelado
                                    </span>
                                    :
                                    null
                          }
                          <div className="media d-flex align-items-center">
                            <div>
                              <br />
                              <div className="media d-flex justify-content-between">
                                <h3 className="mb-0 fs--1">
                                  {Data.name}
                                </h3>
                              </div>
                            </div>
                          </div>
                          <span style={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
                            <span className='d-flex flex-wrap'>
                              <span style={{ color: 'rgb(245, 71, 73)', wordBreak: 'break-word' }}>
                                <i className="bi bi-headset"></i> contatos
                              </span>
                              <hr style={{ width: '100%' }} />
                              <ul>
                                <li style={{ fontSize: '15px', fontWeight: 'bold', wordBreak: 'break-word' }}>
                                  {Data.email}
                                </li>
                                <li style={{ fontSize: '15px', fontWeight: 'bold', wordBreak: 'break-word' }}>
                                  {Data.phone}
                                </li>
                              </ul>
                            </span>
                          </span>
                        </span>
                        <hr style={{ margin: '8px 0' }} />
                        <span style={{ display: 'flex', flexDirection: 'row', gap: 1 }}>
                          <a href={`/produto?p=${Data.id}`} className="btn btn-primary py-1 px-3 mt-2" style={{ maxWidth: 100 }}>
                            ver
                          </a>
                          <a
                            className="btn btn-light py-1 px-3 mt-2"
                            style={{ maxWidth: 100 }}
                            onClick={() => gerenciarStore(Data)}
                          >
                            gerenciar
                          </a>
                        </span>
                      </div>
                    </li>
                  ))}
                </ul>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <Modal className="modal fade" show={modalManagerStore} style={{ zIndex: "100 ! important", position: 'fixed' }}>
        <button onClick={() => setModalManagerStore(false)} className='btn btn-light p-1' style={{ width: 30, height: 30, position: 'absolute', right: 6, top: 6, borderRadius: '100%' }}>
          <i className="bi bi-x-circle" style={{ fontSize: 14 }}></i>
        </button>
        <span
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'column',
            justifyContent: 'spaceBetween',
            width: '100%',
            margin: '10px 0',
            justifyContent: 'space-around',
            justifyContent: 'center',
            alignContent: 'center'
          }}
        >
          <span
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              flexDirection: 'column',
              justifyContent: 'spaceBetween',
              justifyContent: 'space-around',
              padding: '14px',
              justifyContent: 'center',
              gap: '10px',
              border: '1px #f6f6f6 solid'
            }}
          >
            <span style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
              <h5 className="" style={{ margin: 0 }}><i className="bi bi-shop"></i></h5>
              <h5 className="text-primary" style={{ margin: 0 }}>Gerenciar loja</h5>
            </span>
            <span style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: 8, textAlign: 'center' }}>
              {objManaged ?
                <span style={{ display: 'flex', flexDirection: 'column', width: '100%', gap: 5 }}>
                  <h3>{objManaged.name}</h3>
                  <span style={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
                    <span className='d-flex flex-wrap'>
                      <ul style={{ display: 'flex', flexDirection: 'column', gap: 6, textAlign: 'center', width: "100%" }}>
                        <li style={{ fontSize: '15px', fontWeight: 'bold', wordBreak: 'break-word' }}>
                          {objManaged.email}
                        </li>
                        <li style={{ fontSize: '15px', fontWeight: 'bold', wordBreak: 'break-word' }}>
                          {objManaged.phone}
                        </li>
                      </ul>
                    </span>
                  </span>
                </span>
                : null}
            </span>

            {objManaged.status === 0 ?
              <span style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', gap: 3, marginTop: 15 }}>
                <button onClick={() => (approveStore(objManaged.id) , setModalSearch(false))} className='btn btn-primary'>
                  aprovar
                </button>
                <button onClick={() => (rejectStore(objManaged.id) , setModalSearch(false))} className='btn btn-primary'>
                  rejeitar
                </button>
              </span>
              : objManaged.status === 1 ?
                <span style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', gap: 3 }}>
                  <button onClick={() => (inactivateStore(objManaged.id) , setModalSearch(false))} className='btn btn-primary'>
                    inativar
                  </button>
                </span>
                : null
            }
          </span>
        </span>
      </Modal>
      <Modal show={modalSearch}>
        <button onClick={() => setModalSearch(false)} className='btn btn-light p-1' style={{ width: 30, height: 30, position: 'absolute', right: 6, top: 6, borderRadius: '100%' }}>
          <i className="bi bi-x-circle" style={{ fontSize: 14 }}></i>
        </button>
        <span
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'column',
            justifyContent: 'spaceBetween',
            width: '100%',
            margin: '10px 0',
            justifyContent: 'space-around',
            justifyContent: 'center',
            alignContent: 'center'
          }}
        >
          <span
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              flexDirection: 'column',
              justifyContent: 'spaceBetween',
              justifyContent: 'space-around',
              padding: '14px',
              justifyContent: 'center',
              gap: '10px',
              border: '1px #f6f6f6 solid'
            }}
          >
            <span style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
              <h5 className="" style={{ margin: 0 }}><i className="fa-solid fa-magnifying-glass"></i></h5>
              <h5 className="text-primary" style={{ margin: 0 }}>Pesquisar Loja</h5>
            </span>
            <span style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: 8 }}>
              {searchSteps === 'name' ?
                <span style={{ display: 'flex', flexDirection: 'row', width: '100%', gap: 5 }}>
                  <input onChange={(e) => setProductNameSearch(e.target.value)} placeholder="pesquisar por nome" style={{ width: '100%', padding: '2px 5px', textAlign: 'center', height: 40 }} />
                  <button className={`btn btn-light`} style={{ fontSize: 12 }} onClick={() => handleStoreFilter(searchValueType, productNameSearch)}>buscar</button>
                </span>
                : searchSteps === 'address' ?
                  <span style={{ display: 'flex', flexDirection: 'row', width: '100%', gap: 5 }}>
                    <span style={{ display: 'flex', flexDirection: 'row' }}>
                      <input placeholder="pesquisar por endereço" style={{ width: '100%', padding: '2px 5px', textAlign: 'center', height: 40 }} />
                      <select style={{ width: '120px', textAlign: 'center', height: 40, color: 'gray' }}>
                        <option onChange={(e) => { setValueType('street') }} >rua</option>
                        {/* <option>número</option> */}
                        <option onChange={(e) => setValueType('neighborhood')}>bairro</option>
                        <option onChange={(e) => setValueType('city')}>cidade</option>
                      </select>
                    </span>
                    <button className={`btn btn-light`} style={{ fontSize: 12 }} onClick={() => handleStoreFilter(searchValueType, productNameSearch)}>buscar</button>
                  </span>
                  : null}
            </span>
            <span style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'center', gap: 5 }}>
              <button className={`btn ${searchSteps === 'name' ? 'btn-primary-outline text-primary' : 'btn-primary text-light'} border border-primary`} style={{ fontSize: 12 }} onClick={() => setSearchSteps('name')}>nome</button>
              <button className={`btn ${searchSteps === 'address' ? 'btn-primary-outline text-primary' : 'btn-primary text-light'} border border-primary`} style={{ fontSize: 12 }} onClick={() => setSearchSteps('address')}>endereço</button>
            </span>
          </span>
        </span>
      </Modal>
    </>
  )
}
export default Applys;